<template>
    <div class="accordion-item">

        <h2 class="accordion-header">
            <button class="accordion-button collapsed" data-bs-toggle="collapse" :data-bs-target="'#template_'+index" type="button">
                <i class="fab me-2" :class="{'fa-linux': template.osType === 'linux', 'fa-windows': template.osType === 'windows'}"></i>
                {{template.name}}
            </button>
        </h2>
        <div :id="'template_'+index" class="accordion-collapse collapse">
            <div class="accordion-body">
                <p class="card-text">{{template.description}}</p>
                <table class="table table-sm table-borderless">
                    <tbody>
                        <tr>
                            <th>Créé par</th>
                            <td><em>{{ (template.createdBy) ? template.createdBy.email : "Ancien utilisateur" }}</em></td>
                        </tr>
                        <tr>
                            <th>Type d'instance</th>
                            <td><em>{{template.instanceType}}</em></td>
                        </tr>
                        <tr>
                            <th>Taille du disque</th>
                            <td><em>{{template.rootDiskSize}} Go</em></td>
                        </tr>
                    </tbody>
                </table>
                <div class="float-end">
                    <button class="btn btn-outline-secondary btn-sm" @click="createClicked(template)"><i class="fas fa-copy me-2"></i>Créer</button>
                </div>
                <br>
            </div>
        </div>

    </div>
</template>

<script setup>

    import { defineProps, defineEmits } from 'vue';

    let props = defineProps(["template","index"])
    let emits = defineEmits(["createClicked"]);

    function createClicked(template){
        emits('createClicked', template);
    }

</script>