<template>

    <!-- If not loaded -->
    <div class="container" v-if="!loaded">
        <div class="row">
            <div class="alert alert-dark my-3">
                <div class="spinner-border spinner-border-sm me-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <i><small>{{loadText}}</small></i>
            </div>
        </div>
    </div>

    <!-- If loaded -->
    <div class="container" v-else>

        <!-- If user didn't have infras -->
        <div class="row" v-if="userInfras.length === 0">
            <div class="col mt-3">
                <div class="card">
                    <div class="card-header">
                        <h6 class="card-text">
                            <i class="bi bi-exclamation-triangle-fill me-2 text-warning"></i>
                            Attention
                        </h6>
                    </div>
                    <div class="card-body">
                        <p class="card-text">Vous ne disposez pas encore d'infrastructure, ou ne disposez pas d'au moins une infrastructure démarée. Veuillez en <router-link :to="{name: 'Dashboard.datacenters'}">créer/démarrer</router-link> une, avant de pouvoir continuer dans cette section.</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- If user had at least 1 infra -->
        <div class="row" v-else>

            <!-- Summary the quotas limits of the user -->
            <div class="col-md-auto">

                
                <div class="card my-3">
                    <div class="card-header">
                        <h6 class="card-text">Filtrer</h6>
                    </div>
                    <div class="card-body">
                        <div class="input-group">
                            <input type="text" name="" id="" class="form-control" v-model="searchedValue">
                            <button class="btn btn-outline-secondary" @click="search()"><i class="bi bi-search"></i></button>
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-header">
                        <h6 class="card-text"><i class="bi bi-exclamation-triangle-fill text-warning me-2" v-if="userLimits.reached"></i>Vos ressources disponibles</h6>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" :class="{'text-danger' : userLimits.freeInfras <= 0}"><i class="bi bi-collection-fill me-2"></i>Infrastructures ({{userLimits.freeInfras}}/{{userInfo.maxInfras}})</li>
                        <li class="list-group-item" :class="{'text-danger' : userLimits.freeInstances <= 0}"><i class="fas fa-server me-2"></i>Instances ({{userLimits.freeInstances}}/{{userLimits.totalInstances}})</li>
                        <li class="list-group-item" :class="{'text-danger' : userLimits.freeStorage <= 0}"><i class="bi bi-device-hdd-fill me-2"></i>Stockage ({{userLimits.freeStorage}} / {{userLimits.totalStorage}})</li>
                    </ul>
                </div>
            </div>

            <div class="col">
                <div class="card mt-3">
                    <div class="card-header">
                        <h6 class="card-text">Modèles</h6>
                    </div>
                    <div class="card-body">
                        
                        <div class="alert alert-warning" v-if="userLimits.reached">
                            <h6 class="alert-heading"><i class="bi bi-exclamation-triangle-fill text-warning me-2"></i>Limites atteintes</h6>
                            <p class="mb-0">Vous ne pouvez pas lancer de nouvelles machines car une ou plusieurs de vos limites sont atteintes.</p>
                        </div>

                        <template v-if="searchLoaded">

                            <div class="accordion">
                                <describe-template-vue @createClicked="showCreateFromTemplate" 
                                    :template="template"
                                    :index="index" v-for="(template,index) in templates"
                                    :key="template._id">
                                </describe-template-vue>
                            </div>

                        </template>
                        <template v-else>
                            <div class="spinner-border spinner-border-sm me-2" role="status"></div>
                            <small>Chargement</small>
                        </template>

                    </div>
                </div>
            </div>

        </div>

    </div>

    <!-- CreateModal -->
    <create-from-template-vue v-if="showCreateModal"
        :infras="userInfras"
        :user-limits="userLimits"
        :template="templateSelected"
        :user-info="userInfo"
        @close="showCreateModal = false"
        @close-confirm="reload()"
        modalId="CreateFromTemplateModal">
    </create-from-template-vue>

</template>

<script setup>

    import DescribeTemplateVue from "./components/DescribeTemplate.vue";
    import CreateFromTemplateVue from "./modals/CreateFromTemplate.vue";
    import axios from "axios";
    import {defineProps, onMounted, ref} from "vue";
    import {useRouter} from "vue-router";

    const router = useRouter();

    let props = defineProps(["userInfo"])

    let loaded = ref(false);
    let loadText = ref("Chargement en cours ...");

    let templates = ref([]);
    let userInfras = ref([]);
    let userLimits = ref(null);

    let searchedValue = ref("");
    let searchLoaded = ref(true);

    let showCreateModal = ref(false);
    let templateSelected = ref(null);

    onMounted( () => {
        load();
    })

    function reload(){
        // document.location = "/";
        router.push("/");
    }

    function load(){
        loaded.value = false;
        loadText.value = "Chargement données infras...";
        axios.get("/api/users/me/infras")
            .then( answer => {
                userInfras.value = answer.data.infrasResponse.filter( i => Number.parseInt(i.status) !== 7 && Number.parseInt(i.status) !== 6 && Number.parseInt(i.status) !== 5 && Number.parseInt(i.status) !== 4 && Number.parseInt(i.status) !== 1 && Number.parseInt(i.status) !== 0 && Number.parseInt(i.status) !== 2);
                loadText.value = "Chargement de vos limites...";
                return axios.get("/api/users/me/limits");
            })
            .then( answer => {
                userLimits.value = answer.data;
                loadText.value = "Chargement des modèles...";
                return axios.get("/api/templates?limit=100");
            })
            .then( answer => {
                templates.value = answer.data;
                loaded.value = true;
            })
            .catch( err => {
                alert(err);
            })
    }

    function search(){
        searchLoaded.value = false;
        axios.get("/api/templates?fields=name:"+searchedValue.value)
            .then( answer => {
                templates.value = answer.data;
                searchLoaded.value = true;
            })
            .catch( err => {
                alert(err);
                searchLoaded.value = true;
                load();
            })
    }

    function showCreateFromTemplate(template){
        showCreateModal.value = true;
        templateSelected.value = template;
    }

</script>

<style>

</style>