<template>
    <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="lead">Nouvelle machine</h6>
                    <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-warning" v-if="userLimits.reached">
                        <h6 class="alert-heading"><i class="bi bi-exclamation-triangle-fill text-warning me-2"></i>Limites atteintes</h6>
                        <p class="mb-0">Vous ne pouvez pas lancer de nouvelles machines car une ou plusieurs de vos limites sont atteintes.</p>
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Modèle choisi</label>
                        <input type="text" name="" id="" class="form-control" readonly :value="template.name">
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Nom</label>
                        <input type="text" name="" id="" class="form-control" v-model="targetName">
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Infrastructure cible</label>
                        <select name="" id="" class="form-select" v-model="targetInfra" :disabled="targetInfra !== 0">
                            <option :value="infra" v-for="infra in infras" :key="infra._id">{{ infra.name }}</option>
                            <option selected value="0">--</option>
                        </select>
                    </div>
                    <div class="mb-3" v-if="targetInfra !== 0">
                        <label for="" class="form-label">Réseau cible de l'infrastructure</label>
                        <select name="" id="" class="form-select" v-model="targetNetwork" :disabled="targetNetwork !== 0">
                            <option :value="targetInfra.states.resources.filter( r => r.type === 'aws_subnet' && r.name === subnet)[0].instances[0]" v-for="subnet in Object.keys(targetInfra.model.resource.aws_subnet)" :key="subnet">{{ subnet }}</option>
                            <option selected value="0">--</option>
                        </select>
                    </div>
                    <div class="mb-3" v-if="targetNetwork !== 0">
                        <label for="" class="form-label">Adresse IP<small>(vide si dhcp)</small></label>
                        <div class="input-group">
                            <input type="text" name="" id="" class="form-control text-center" disabled :value="targetNetwork.attributes.cidr_block.replace('/24','').split('.')[0]">
                            <span class="input-group-text">.</span>
                            <input type="text" name="" id="" class="form-control text-center" disabled :value="targetNetwork.attributes.cidr_block.replace('/24','').split('.')[1]">
                            <span class="input-group-text">.</span>
                            <input type="text" name="" id="" class="form-control text-center" disabled :value="targetNetwork.attributes.cidr_block.replace('/24','').split('.')[2]">
                            <span class="input-group-text">.</span>
                            <input type="number" max="200" min="10" name="" id="" class="form-control text-center" v-model="targetIp">
                        </div>
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click.prevent="close()" :class="{disabled: btnStartCreationDisabled }">Annuler</button>
                    <button type="button" class="btn btn-primary" @click.prevent="startCreationClicked" v-if="!userLimits.reached" :class="{disabled: btnStartCreationDisabled || targetNetwork === 0 || targetName === ''}">
                        <span class="spinner spinner-border spinner-border-sm me-2" v-if="btnStartCreationDisabled"></span>
                        <i class="bi bi-building me-2" v-if="!btnStartCreationDisabled"></i>Lancer la construction
                    </button>
                    <button type="button" class="btn btn-primary" disabled @click.prevent="startCreationClicked" v-if="userLimits.reached">
                        <i class="bi bi-x me-2"></i>Lancer la construction
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" id="CreateFromTemplateConfirm">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Confirmation</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>{{creationDoneMessage}}</p>
                <div class="alert alert-warning mb-0" v-if="errorMessage">
                    <span class="mb-0">{{errorMessage}}</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click.prevent="closeConfirm()">Close</button>
            </div>
            </div>
        </div>
    </div>

</template>

<script setup>

    import { defineProps, defineEmits, onMounted, ref } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from 'axios';

    let props = defineProps(["modalId","show","template","infras","userInfo","userLimits"]);
    let emits = defineEmits(["close","closeConfirm","startCreationClicked"]);

    let targetInfra = ref(0);
    let targetNetwork = ref(0);
    let targetIp = ref("");
    let targetName = ref("");

    let btnStartCreationDisabled = ref(false);
    let creationDoneMessage = ref("");
    let errorMessage = ref(null);

    onMounted( () => {
        var myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
        myModal.show();
        load();
    });

    function close(){
        if(btnStartCreationDisabled.value) return false;
        var myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
        myModal.hide();
        emits("close");
    }

    function closeConfirm(){
        var myModal = Modal.getOrCreateInstance(document.querySelector("#CreateFromTemplateConfirm"));
        myModal.toggle();
        if(!errorMessage.value) emits("closeConfirm")
        else emits("close");
    }

    function load(){

    }

    function startCreationClicked(){
        btnStartCreationDisabled.value = true;
        creationDoneMessage.value = "";
        errorMessage.value = null;

        axios.post(`/api/users/me/infras/${targetInfra.value._id}/instances`, {
            template_id: props.template._id,
            network_id: targetNetwork.value.attributes.id,
            ip: (targetIp.value !== "") ? targetNetwork.value.attributes.cidr_block.replace(".0/24","."+targetIp.value) : "",
            name: targetName.value
        }).then( answer => {
            var myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.toggle();

            creationDoneMessage.value = "Votre demande de création d'instance à bien été prise en compte, elle apparaitra dans votre espace d'ici quelques instants.";
            myModal = Modal.getOrCreateInstance(document.querySelector('#CreateFromTemplateConfirm'));
            myModal.toggle();
            btnStartCreationDisabled.value = false;
        })
        .catch( e => {
            var myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.toggle();
            
            creationDoneMessage.value = "Une erreur est survenue :";
            errorMessage.value = (e.response.data.message) ? e.response.data.message : e;

            myModal = Modal.getOrCreateInstance(document.querySelector('#CreateFromTemplateConfirm'));
            myModal.toggle();
            btnStartCreationDisabled.value = false;
        })
    }

</script>